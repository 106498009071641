<template>
    <div class="new-subscribue-container">
        <div class="new-sub-content">
            <div class="title">Subscribe to our newsletter</div>
            <div class="tip">Signup to our mailing list for news and updates.</div>
            <div class="form-item">
                <input type="text" placeholder="Email A ddress">
                <button>JOIN</button>
            </div>
        </div>
        
        
    </div>
</template>

<script>
export default {
    props:[],
    data() {
        return {
           
        }
    },

}
</script>

<style lang="stylus" scoped>
.new-subscribue-container 
    margin-top 6.25rem
    padding 3.75rem 13.75%
    background #ede8d7
    display flex
    align-items center
    justify-content center
    .new-sub-content
        text-align center
        .title 
            font-size 1.5rem
            color #222
            font-family 'EB Garamond', serif
        .tip 
            font-size .875rem
            color #5f5f5f
            margin 1.3125rem 0 1.875rem
        .form-item
            input 
                width 28.125rem
                height 3.125rem
                line-height 3.125rem
                padding-left 1.25rem
                font-size .875rem
                color #5f5f5f
                border .0625rem solid #000
                background transparent
            button
                background #000
                color #fff
                border .0625rem solid #000
                height 3.25rem
                line-height 3.25rem
                padding 0 3.125rem
                cursor pointer
                opacity 1
                transition all 0.3s
                &:hover
                    opacity 0.8
                    transition all 0.3s
        
        


</style>
