<template>
    <div class="collection-list-container">
        <div class="img-box">
            <img :src="collectionData.img" alt="">
        </div>
        <div class="title">{{collectionData.title}}</div> 
        <div class="tip">{{collectionData.tip}}</div>
        <div class="link">VIEW ALL</div>
        
    </div>
</template>

<script>
export default {
    props:['collectionData'],
    data() {
        return {
           
        }
    },

}
</script>

<style lang="stylus" scoped>
.collection-list-container
    img 
        display block
    .title 
        font-size 1.875rem
        color #222
        margin 2.125rem 0 1.25rem
        font-family 'EB Garamond', serif
    .tip 
        font-size .875rem
        color #5f5f5f
        letter-spacing .0625rem
        line-height 1.5rem
        margin-bottom 2.125rem
    .link 
        font-size .75rem
        color #000
        font-weight 600        
        cursor pointer
        &:hover
            text-decoration underline
            color #b19d75
.collection-item:nth-child(2) .img-box
    position relative
    &:after
        content: ""
        position: absolute
        height: 100%
        border-left: 1px dashed #a0a0a0
        top: 0
        left: -1.25rem
    &:before
        content: ""
        position: absolute
        height: 100%
        border-left: 1px dashed #a0a0a0
        top: 0
        right: -1.25rem



</style>
