<template>
    <div class="product-list-container">
        <!-- productList -->
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="item in dataList" :key="item.title + item.id">                    
                    <router-link :to="{name:'detail',params:{title:item.handle,id:item.id}}" class="collection-list-item" >
                        <img :src="item.img.src" alt="">
                        
                    </router-link>
                    <div class="product-title">{{item.title}}</div>
                        <div class="price">${{item.price}}</div>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>                          
            </swiper>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        swiper,
        swiperSlide
     },
    props:{
        listData:{
            type:Object,
            default:()=>({items:[],title:''})
        }
    },
    mounted(){
        
    },
    computed:{
        dataList(){            
            if(this.listData.items){
                return this.listData.items.map((item)=>{
                    item.img = JSON.parse(item.image)
                    return item
                }) 
            } 
                                                        
        }                           
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 15,
                loop: true,
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
        }
    },

}
</script>

<style lang="stylus" scoped>
.product-list-container
    background #ffffff
    padding 1.25rem 13.75% 0
    .title 
        font-size 1.875rem
        color #000
        text-align center
        margin-bottom 2.25rem
    .product-title 
        font-size .875rem
        color #222
        margin-top 1.25rem
    .price 
        font-size: 1rem
        color: #222
        margin-top: .75rem
</style>