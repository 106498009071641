<template>
    <div class="product-list-container" v-show="dataList && dataList.length>0">
        <div class="title">{{listData.title}}</div> 
        <!-- productList -->
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(item,index) in dataList" :key="item.title + index">
                    
                    <router-link :to="{name:'detail',params:{title:item.handle,id:item.id}}" class="collection-list-item" >          
                        <img :src="item.img.src" alt="">                        
                    </router-link>
                    <div class="product-title">{{item.title}}</div>
                        <div class="price">${{item.price}}</div>
                
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>                          
            </swiper>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        swiper,
        swiperSlide
     },
    props:{
        listData:{
            type:Object,
            default:()=>({items:[],title:'HOT SALE'})
        }
    },    
    data() {
        return {
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 15,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
        }
    },
    computed:{
        dataList(){            
            console.log(this.listData.items)
            if(this.listData.items&&this.listData.items.length > 0){
                return this.listData.items.map((item)=>{
                    if(item.image){
                        item.img = JSON.parse(item.image)
                    }                                        
                    return item
                }) 
            }                                               
        }                            
    },
    created(){

    },
    updated(){
        console.log(this.dataList)
    }

}
</script>

<style lang="stylus" scoped>
.product-list-container
    background #ffffff
    padding 5.625rem 13.75% 0
    .title 
        font-size 1.875rem
        color #000
        text-align center
        margin-bottom 2.25rem
        font-family 'EB Garamond', serif
    .product-title 
        font-size .875rem
        color #222
        margin-top 1.25rem
    .price 
        font-size: 1rem
        color: #222
        margin-top: .75rem
        text-transform capitalize
</style>
