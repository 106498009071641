<template>
    <div class="index-container">
        <!-- banner -->
        <div class="banner-container">
            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(item,index) in bannerList" :key="item + index">
                    <img :src="item" alt="">
                </swiper-slide> 
                <div class="swiper-pagination" slot="pagination"></div>           
            </swiper>
        </div>
        <!-- product lists -->
        <ProductList v-if="hotSaleData.items.length>0" :listData = "hotSaleData" class="hotsale" />
        <ProductList v-if="newArrivalData.items.length>0" :listData = "newArrivalData" class="new"/>
        <!-- video -->
        <div class="video-container">
            <div class="video-content">
                <img :src="videoData.src" alt="">
            </div>
            <div class="video-text-content">
                <div class="video-text">
                    <div class="title">{{videoData.title}}</div>
                    <div class="tip">{{videoData.tip}}</div>
                    <div class="video-button">{{videoData.btn}}</div>
                </div>
            </div>            
        </div>
        <!-- products-tab -->
        <div class="products-tab-container">
            <el-tabs v-model="tabActive">
                <el-tab-pane label="Clothing" name="first">
                    <ProductListTab :listData = "tab1"/>
                </el-tab-pane>
                <el-tab-pane label="Swimwear" name="second">
                    <ProductListTab :listData = "tab2"/>
                </el-tab-pane>
                <el-tab-pane label="Lingerie" name="third">
                    <ProductListTab :listData = "tab3"/>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- certificate -->
        <div class="video-container">            
            <div class="certificate-text-content">
                <div class="video-text">
                    <div class="title">{{certificateData.title}}</div>
                    <div class="tip">{{certificateData.tip}}</div>
                    <div class="video-button">{{certificateData.btn}}</div>
                </div>
            </div>
            <div class="video-content">
                <img :src="certificateData.src" alt="">
            </div>        
        </div>
        <!-- collection list -->
        <div class="collection-container">
            <div class="collection-item" v-for="item in collectionList" :key="item.img">
                <CollectionList :collectionData = "item" />
            </div>
        </div>
        <!-- subscribe -->
        <NewSubscribe/>
        <!--  label -->
        <div class="coupon">THANKS FOR STOPPING BY - TAKE 20% OFF WITH CODE:SAVE20</div>
    </div>
</template>

<script>
import ProductList from '@/components/product-lists/ProductList.vue'
import ProductListTab from '@/components/product-lists/ProductListTab.vue'
import NewSubscribe from '@/components/product-lists/NewSubscribe.vue'
import CollectionList from '@/components/product-lists/CollectionList.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import {getProductList} from '@/api/mall/index.js'
import 'swiper/swiper-bundle.css'

export default {
    components: {
        swiper,
        swiperSlide,
        ProductList,
        ProductListTab,
        CollectionList,
        NewSubscribe
     },
     data() {
         return {
             tabActive:'first',
             bannerList:[
                 require("@/assets/imgs/img01.jpg"),
                 require("@/assets/imgs/img01.jpg")
             ],
             hotSaleData:{
                 items:[],
                 title:''
             },
             newArrivalData:{
                 items:[],
                 title:''                                  
             },
             tab1:{items:[]},
             tab2:{items:[]},
             tab3:{items:[]},
             videoData:{
                 src:require("@/assets/imgs/img03.jpg"),
                 title:'Video title',
                 tip:'The modern woman is constantly on the go and she needs a wardrobe that can keep up.',
                 btn:'View All'
             },
             certificateData:{
                 src:require("@/assets/imgs/img04.jpg"),
                 title:'Certificate of title',
                 tip:'The modern woman is constantly on the go and she needs a wardrobe that can keep up.',
                 btn:'View All'
             },
             collectionList:[
                 {
                    img:require("@/assets/imgs/img05.jpg"),
                    title:'Never compromised',
                    tip:'We are design and product obsessed. Uncompromising in the style, quality and performance of every product we create. ',
                    link:'/'
                 },
                 {
                    img:require("@/assets/imgs/img06.jpg"),
                    title:'Never compromised',
                    tip:'We are design and product obsessed. Uncompromising in the style, quality and performance of every product we create. ',
                    link:'/'
                 },
                 {
                    img:require("@/assets/imgs/img07.jpg"),
                    title:'Never compromised',
                    tip:'We are design and product obsessed. Uncompromising in the style, quality and performance of every product we create. ',
                    link:'/'
                 }
             ],
             swiperOption: {
                slidesPerView: 1,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
         }
     },
     mounted(){
         this._getProductList('43d81ef4-3029-4746-9234-92e526503ad5')
         this._getProductList('17b55d56-2f03-4e3f-8e7e-6a64ef2b4a55','new')
         this._getProductList('8decd5bc46424d63969ba9f1bca954ef','tab1')
         this._getProductList('6a45c8c2-c06f-4aa7-9c13-f912f0a08ba4','tab2')
         this._getProductList('19fc283e-2eac-40ca-b5db-5928ab04bcd2','tab3')
     },
     methods:{
         _getProductList(id,type){
            getProductList(JSON.stringify({collectId:id})).then(res=>{  
                let [...list] = res.data.list.resultList
                list.forEach(item => {
                    item.img = JSON.parse(item.image)
                });               
                if(type == 'tab1'){                                   
                    this.tab1.items = list 
                }
                else if(type == 'tab2'){                                    
                    this.tab2.items = list 
                }
                else if(type == 'tab3'){                                   
                    this.tab3.items = list 
                }
                else if(type == 'new'){                                    
                    this.newArrivalData.items = list                     
                    this.newArrivalData.title = 'New Arrival'
                }
                else{                                   
                    this.hotSaleData.items = list                     
                    this.hotSaleData.title = 'Best Sellers'   
                }
                                                                          
            })
        }
     }
}
</script>

<style lang="stylus" scoped>
    
.index-container
    background #fff
.banner-container
    font-size 0
.product-list-container.new 
    padding 0
    padding-top 2.875rem
    border-top 1px dotted #a0a0a0
    margin 5.625rem 13.75% 0
.video-container 
    margin-top 5.3125rem
    background #f0eeed
    padding 4.375rem 13.75%
    display flex
    align-items center
    justify-content space-between
    .certificate-text-content
        padding-right 3.9375rem
        .video-text
            padding-left 0        
    .video-text
        padding-left 3.9375rem
        .title
            font-size 1.875rem
            color #222222
            margin-bottom 2.25rem
            font-family 'EB Garamond', serif
        .tip
            font-size .875rem
            line-height 1.5rem
            letter-spacing .0625rem
            color #5a5a5a
            margin-bottom 3.125rem
        .video-button
            width 9.375rem
            height 3.125rem
            line-height 3.125rem
            background #ffffff
            color #000
            font-size .875rem
            letter-spacing .0625rem
            text-align center
            cursor pointer
            &:hover
                background #000000
                color #ffffff
.index-container >>> .el-tabs__nav-wrap::after
    display none
.index-container >>> .el-tabs__header
    padding 0 13.75%
    margin-top 5rem
.index-container >>> .el-tabs__nav-scroll
    display flex
    align-items center
    justify-content center
.index-container >>> .el-tabs__item
    height auto 
    line-height intial 
    font-size 1.5rem
    color #808080
    font-family 'EB Garamond', serif
.index-container >>> .el-tabs__item.is-active
    font-size 1.875rem
    color #222
    font-weight 600
.index-container >>> .el-tabs__active-bar
    background #222
.collection-container 
    padding 54px 13.75% 0
    display flex
    justify-content space-between
.collection-item:nth-child(2) 
    padding 0 1.25rem
    margin 0 1.25rem
    
.coupon 
    height 3.125rem
    background-color #b19d75
    line-height 3.125rem
    text-align center
    font-size .875rem
    letter-spacing .0625rem
.index-container >>> .swiper-pagination-bullet
    background #313131
    opacity 1
.index-container >>> span.swiper-pagination-bullet.swiper-pagination-bullet-active 
    background #fff
.link
    cursor pointer
.index-container >>> .swiper-button-prev,.index-container >>> .swiper-button-next
    color #999
.index-container >>> .swiper-button-prev, .swiper-container-rtl .swiper-button-next
    left 0
    height 3.75rem
    width 1.875rem
.index-container >>> .swiper-button-next, .swiper-container-rtl .swiper-button-prev
    right 0
    height 3.75rem
    width 1.875rem
.index-container >>> .swiper-container:hover .swiper-button-prev
    background: rgba(0,0,0,0.3)
    border-radius: 0 1.875rem 1.875rem 0
    color: #fff
.index-container >>> .swiper-container:hover .swiper-button-next
    background: rgba(0,0,0,0.3)
    border-radius: 1.875rem 0 0 1.875rem
    color: #fff
.index-container >>> .swiper-button-prev:after
    position: absolute;
    left: 5px;
    font-size 1.25rem
.index-container >>> .swiper-button-next:after
    position: absolute;
    right: 5px;
    font-size 1.25rem
.index-container >>> .swiper-container-horizontal > .swiper-pagination-bullets
    bottom 1.875rem
.index-container >>> .el-tabs__item.is-active,.index-container >>> .el-tabs__item:hover
    color #000 !important
.index-container >>> .el-tabs__item:hover
    text-decoration: underline
.index-container >>> .el-tabs__item.is-active:hover
    text-decoration: none

</style>

